<template>
  <div class="sign-in-container">
    <div class="sign-in-form">
      <el-form
        ref="form"
      >
        <div class="login-icon">
          <i class="icons el-icon-user-solid"></i>
        </div>
        <h1 class="sitn-in-title">
          Mero Jodi - Admin Login
        </h1>
        <el-form-item prop="username">
          <el-input
            type="email"
            v-model="userData.username"
            placeholder="Username"
          />
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model.trim="userData.password"
            type="password"
            placeholder="Password"
          />
        </el-form-item>

        <el-button
          @click="onLogin"
          class="sign-in-btn is-round"
          type="primary"
        >
          Sign In
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'

export default {
  name: 'Login',

  data () {
    return {
      userData: {
        username: '',
        password: ''
      }
    }
  },

  methods: {
    onLogin () {
      this.loginUser(this.userData)
        .then((res) => {
          this.$router.push({ name: 'admin-dashboard' })
        })
        .catch(() => {
          this.$message({
            message: 'Invalid credentials',
            type: 'success'
          })
        })
    }
  },

  mixins: [userMixin]
}
</script>

<style lang="scss" scoped>
.sign-in-container {
  display: flex;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
background: #f3f2ef;
  position: relative;
}

.sign-in-form {
  position: relative;
  padding: 40px 30px;
  width: 100%;
  max-width: 340px;
  background: #fff;
  border-radius: 10px;
}

.sitn-in-title {
  position: relative;
  margin-bottom: 30px;
  text-align: center;
  font-size: 32px;
}

.sign-in-btn {
  position: relative;
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
  background-color: #dc133d !important;
  border-color: #dc133d !important;
}

.sign-in-form .el-form-item {
  margin-bottom: 5px;
}

.sign-in-form .login-icon{
  text-align: center;
}

.sign-in-form .login-icon .icons{
  padding: 10px;
  text-align: center;
  font-size: 50px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #003895;
  color: #fff;
  border: 2px solid #fff;
}

.sign-in-form h1{
  text-align: center;
  font-size: 25px;
  padding: 15px 0 0 0;
}

.sign-in-form .el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}
</style>
